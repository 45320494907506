/*----------------------------------------*\
  POLYFILLS
\*----------------------------------------*/

import 'whatwg-fetch';

// Promise is required for Fetch on IE 11
import Promise from 'promise-polyfill';

if (!window.Promise) {
  window.Promise = Promise;
}

// missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

//Polyfill for HTMLCollection.forEach
if ('HTMLCollection' in window && !HTMLCollection.prototype.forEach) {
  HTMLCollection.prototype.forEach = function(callback, thisArg) {
    thisArg = thisArg || window;
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
