import store from 'store2';

const DEBUG_KEY = 'DEBUG_MODE';

function enable() {
  store.set(DEBUG_KEY, true);
}

function disable() {
  store.remove(DEBUG_KEY);
}

function isEnabled() {
  return store.has(DEBUG_KEY) && store.get(DEBUG_KEY) === true;
}

export default {
  enable,
  disable,
  isEnabled,
};
