const ActiveHelper = {
  enable(elem) {
    elem.disabled = false;
  },

  disable(elem) {
    elem.disabled = true;
  },
};

/**
 *  ALL HTML ELEMENTS
 */
HTMLElement.prototype.show = function() {
  this.classList.remove('hidden');
};

HTMLElement.prototype.hide = function() {
  this.classList.add('hidden');
};

HTMLElement.prototype.setVisibility = function(status) {
  if (status) {
    this.show();
  } else {
    this.hide();
  }
};

// Create Element.remove() function if not exist
if (!('remove' in HTMLElement.prototype)) {
  HTMLElement.prototype.remove = function() {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}

/**
 *  BUTTONS + SUBMIT ELEMENTS
 */
HTMLAnchorElement.prototype.enable = function() {
  this.classList.remove('btn--disabled');
};

HTMLAnchorElement.prototype.disable = function() {
  this.classList.add('btn--disabled');
};

HTMLButtonElement.prototype.enable = function() {
  ActiveHelper.enable(this);
};

HTMLButtonElement.prototype.disable = function() {
  ActiveHelper.disable(this);
};

HTMLInputElement.prototype.enable = function() {
  if (this.getAttribute('type') === 'submit') {
    ActiveHelper.enable(this);
  }
};

HTMLInputElement.prototype.disable = function() {
  if (this.getAttribute('type') === 'submit') {
    ActiveHelper.disable(this);
  }
};

/**
 *  NodeList
 */
NodeList.prototype.addEventListener = function(event, callback) {
  for (const item of this) {
    item.addEventListener(event, callback);
  }
};
