/* eslint-disable no-console */

import Env from 'components/Environments';

class Logger {
  group(message) {
    if (Env.debugEnabled()) {
      console.group(message);
    }
  }

  groupEnd() {
    if (Env.debugEnabled()) {
      console.groupEnd();
    }
  }

  log(message) {
    if (Env.debugEnabled()) {
      console.log(message);
    }
  }

  warn(message) {
    if (Env.debugEnabled()) {
      console.warn(message);
    }
  }
}

const logger = new Logger();

export default logger;
