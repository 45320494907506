import logger from 'components/Logger';

function trackModal(modalId) {
  track({ event: 'modal', modal: modalId });
}

function trackFeedback(helpfulText) {
  track({ event: 'feedback', feedback: helpfulText });
}

function trackSocialSharing(type, page) {
  track({ event: 'shared', shared: { on: type, page } });
}

function track(params) {
  const _mtm = window._mtm = window._mtm || [];

  logger.group('MTM track');
  logger.log(params);
  logger.groupEnd();
  _mtm.push(params);
}


export default {
  track,
  trackModal,
  trackFeedback,
  trackSocialSharing,
};
