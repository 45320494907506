import debug from 'components/Debug';

function getName() {
  return process.env.NODE_ENV;
}

function debugEnabled() {
  return debug.isEnabled() || isDevelopment();
}

function isDevelopment() {
  return getName() === 'development';
}

function isProduction() {
  return !isDevelopment();
}

function getLocale() {
  return document.querySelector('html').getAttribute('lang');
}

function getCsrfToken() {
  const field = document.querySelector('meta[name="csrf-token"]');
  return field === null ? '' : field.getAttribute('content');
}

export default {
  debugEnabled,
  getCsrfToken,
  getLocale,
  getName,
  isProduction,
  isDevelopment,
};
