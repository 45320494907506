import mtm from 'components/Mtm';

class Sharing {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    const shareLinks = [...document.querySelectorAll('.share-buttons li > a')];

    shareLinks.forEach(link => {
      link.addEventListener('click', event => {
        event.preventDefault();
        mtm.trackSocialSharing(event.target.getAttribute('data-share-type'), window.location.href);
        window.open(event.target.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
        return false;
      });
    });
  }
}

const sharing = new Sharing();

export default sharing;
